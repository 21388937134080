import React, { useState } from "react";

// Tailwind React UI
import { Title } from "tailwind-react-ui";

// Images
import PlaceholderImage from "../../../../images/images/tablemountain_HP.png";
import TrianglesRB from "../../../../images/patterns/patterns-triangles_redblue.svg";
import DotsBlue from "../../../../images/patterns/pattern-dots_blue.svg";
import SquiggleGreen from "../../../../images/patterns/pattern-squiggle_green.svg";

const VideoBlock = ({ block }) => {
  const [show, setIsShow] = useState(false);

  return (
    <>
      <div
        className="w-full block my-10 relative"
        data-component-type="video-block"
      >
        <Title
          level={4}
          font="heading"
          text="primary"
          className="font-heading text-primary relative text-center pb-5"
        >
          {block.heading}{" "}
          <TrianglesRB
            alt="Pattern"
            className="z-20 absolute top-full -left-10"
          />
        </Title>
        <div className="w-full bg-gray-300 relative aspect-video">
          {show ? (
            <div
              style={{ paddingBottom: "56.25%" }}
              className="w-full video-wrapper relative h-0 iframe-container"
              dangerouslySetInnerHTML={{ __html: block.videoEmbed.code }}
            />
          ) : (
            <div className="relative">
              <img className="object-cover" src={PlaceholderImage} alt="This is a placeholder image" />
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <button
                  onClick={(e) => setIsShow(true)}
                  className="p-2 bg-secondary rounded-full shadow-lg"
                  aria-label="Play video"
                >
                  <svg fill="black" className="w-8 ml-1" viewBox="0 0 16 16">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>

        <DotsBlue
          className="absolute bottom-8 -left-10 z-20"
          alt="Dots Blue Pattern"
        />
        <SquiggleGreen
          className="z-20 absolute bottom-4 -right-8 h-24"
          alt="Squiggle Green Pattern"
        />
      </div>
    </>
  );
};

export default VideoBlock;
