import React from "react";
import { Button, Title } from "tailwind-react-ui";

const IconTitle = (props) => {
  let bg;
  switch (props.bg) {
    case "primary":
      bg = "bg-primary";
      break;
    case "green":
      bg = "bg-green";
      break;
    case "red":
      bg = "bg-red";
      break;
    case "teal":
      bg = "bg-teal";
      break;
    default:
      bg = "bg-white";
  }

  return (
    <Button
      is="a"
      href={props.link}
      className="mx-auto text-center px-3 group"
      data-component-type="icon-title"
    >
      <div
        className={`relative flex justify-center flex-align-center text-center mx-auto h-20 w-20 shadow rounded-full overflow-hidden ${bg} transition duration-300 ease-in-out transform group-hover:hover:-translate-1 group-hover:scale-110`}
      >
        <img
          src={props.icon.url}
          alt=""
          width={props.icon.width}
          height={props.icon.height}
          loading="lazy"
          className="top-0 left-0 right-0 bottom-0 m-auto"
        />
      </div>
      <Title
        m="0"
        level={6}
        font="display"
        text="sm"
        className="group-hover:text-green tracking-normal py-5 leading-snug"
      >
        {props.text}
      </Title>
    </Button>
  );
};

export default IconTitle;
