import React from "react";

// React multi carousel
import Carousel from "react-multi-carousel";
// import 'react-multi-carousel/lib/styles.css'

// Tailwind React UI
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  FillButton,
  Text,
  Title,
} from "tailwind-react-ui";

import ArrowLeft from "../images/icons/icon-arrow_left_green.svg";
import ArrowRight from "../images/icons/icon-arrow_right_green.svg";
import ImgixFluidImage from "./ImgixFluidImage";


const LogoCarousel = ({ block }) => {
  const CustomLeft = ({ onClick }) => (
    <button
      className="absolute right-full -mr-10 arrow left px-2 py-3 shadow z-10 bg-white"
      onMouseDown={onClick}
      aria-label="Previous slide"
    >
      <ArrowLeft alt="Previous slide" />
    </button>
  );
  const CustomRight = ({ onClick }) => (
    <button
      className="absolute left-full -ml-10 arrow right px-2 py-3 shadow z-10 bg-white"
      onMouseDown={onClick}
      aria-label="Next slide"
    >
      <ArrowRight alt="Next slide" />
    </button>
  );

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay={false}
      centerMode={false}
      className="my-10"
      containerClass="container-with-dots carousel-container"
      dotListClass=""
      draggable={false}
      focusOnSelect={false}
      customLeftArrow={<CustomLeft />}
      customRightArrow={<CustomRight />}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={150}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      partialVisible={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 2500,
            min: 1280,
          },
          items: 4,
          slidesToSlide: 4,
          partialVisibilityGutter: 0,
        },
        tablet: {
          breakpoint: {
            max: 1279,
            min: 1024,
          },
          items: 3,
          slidesToSlide: 3,
          partialVisibilityGutter: 0,
        },
        largerPhone: {
          breakpoint: {
            max: 1023,
            min: 600,
          },
          items: 2,
          slidesToSlide: 2,
          partialVisibilityGutter: 0,
        },
        mobile: {
          breakpoint: {
            max: 599,
            min: 0,
          },
          items: 1,
          slidesToSlide: 1,
          partialVisibilityGutter: 0,
        },
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={3}
      swipeable
      data-component-type="logo-carousel"
    >
      {block.map((card, i) => (
        <Card
          key={i}
          shadow
          className="shadow flex flex-col relative h-full text-center mx-3 p-3 lg:p-5"
        >
          <Box className="w-full mb-1 mx-auto text-center border-gray-300 flex items-center h-64">
	    <ImgixFluidImage className="mx-auto w-64 h-auto max-h-64 block" alt={card.awardLogo[0].imageAlt ?? card.awardLogo[0].title ?? card.title} imageUrl={card.awardLogo[0].url} ar={card.awardLogo[0].width / card.awardLogo[0].height} h={256} w={256} fit="clip" />
          </Box>
          <CardBody className="pt-3 pb-0">
            <Title
              level={5}
              text="primary"
              className="text-primary text-md font-heading"
              font="heading"
            >
              {card.title}
            </Title>
            {card.description ? (
              <Text
                leading="snug"
                className="leading-snug"
                dangerouslySetInnerHTML={{ __html: card.description }}
              />
            ) : null}
          </CardBody>
          {card.relatedEntry[0] ? (
            <CardFooter className="mt-auto mx-auto text-center">
              <FillButton
                font="display"
                data-type="button"
                bg="white"
                text="primary"
                is="a"
                href={`/${
                  card.relatedEntry[0] ? card.relatedEntry[0].uri : "about-us"
                }`}
                className="font-display bg-white text-primary btn mt-0 px-3 lg:px-8 py-3 rounded-full border-primary focus:border-primary hover:border-primary hover:text-white focus:text-white"
              >
                <span className="block relative">Learn more</span>
                <span className="sr-only">Go to: {card.title}</span>
              </FillButton>
            </CardFooter>
          ) : (
            ""
          )}
        </Card>
      ))}
    </Carousel>
  );
};

export default LogoCarousel;
